import React, { Component } from "react";
import { Tabs } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import local from "../../services/local";
import helper from '../../services/helper'

class TagsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialItems: null,
      activeKey: "1",
    };
  }

  async componentDidMount() {
    const pathname = this.props.history.location?.pathname;
    const search = this.props.history.location?.search;
    const menus = await local.get("meta").menus;
    let menu = menus.find( menu => menu.url === pathname+search)
    if(pathname === '/profile'){
      menu = {sid:'2',name:'Profile',url:pathname,}
    }
    if(menu){
      await this.props.dispatch({
        type: "ADD_TAB",
        payload: {
          key: menus[0].sid,
          label: menus[0].name,
          path: menus[0].url,
          closable: false,
          children: await helper.getComponentByRoute(this.props.history,menus[0].url)
        },
      });
      await this.props.dispatch({
        type: "ADD_TAB",
        payload: {
          key: menu.sid,
          label: menu.name,
          path: menu.url,
          children: await helper.getComponentByRoute(this.props.history,pathname,search)
        },
      });
    }else{
      await this.props.dispatch({
        type: "ADD_TAB",
        payload: {
          key: menus[0].sid,
          label: menus[0].name,
          path: menus[0].url,
          closable: false,
          children: await helper.getComponentByRoute(this.props.history,menus[0].url)
        },
      });
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.tabs !== this.props.tabs) {
      this.setState({
        initialItems: nextProps.tabs.listTabs,
        activeKey: nextProps.tabs.activeTab,
      });
    }
  };

  onChange = async(newActiveKey) => {
    const tabActive = this.state.initialItems.find(
      (tab) => tab.key === newActiveKey
    );
    this.setState({ activeKey: newActiveKey });
    await this.props.history.push(`.${tabActive.path}`);
    await this.props.dispatch({
      type: "SET_ACTIVE",
      payload:newActiveKey
    });
  };

  remove = (targetKey) => {
    let newActiveKey = this.state.activeKey;
    let lastIndex = -1;
    this.state.initialItems.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const newPanes = this.state.initialItems.filter(
      (item) => item.key !== targetKey
    );

    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }

    this.props.dispatch({
      type: "REMOVE_TAB",
      payload: { targetKey, newActiveKey },
    });
    this.setState({ initialItems: newPanes });

    const tabActive = this.state.initialItems.find(
      (tab) => tab.key === newActiveKey
    );
    this.props.history.push(`.${tabActive.path}`);
  };

  onEdit = (targetKey, action) => {
    this.remove(targetKey);
  };
  render() {
    return (
      <div className="tabs-form">
        <Tabs
          className="tabs-custom"
          type="editable-card"
          hideAdd
          defaultActiveKey={'1'}
          activeKey={this.state.activeKey}
          onChange={this.onChange}
          onEdit={this.onEdit}
          items={this.state.initialItems}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return { tabs: state.tabs };
};

export default connect(mapStateToProps)(withRouter(TagsView));
