import React from 'react';
import Loadable from 'react-loadable'

function Loading() {
    return <div>Loading...</div>;
}

const Dashboard = Loadable({
    loader: () => import('./views/Dashboard'),
    loading: Loading
});
const Profile = Loadable({
    loader: () => import('./views/User/Profile'),
    loading: Loading
});


const PageEditor = Loadable({
    loader: () => import('./views/PageManager/PageEditor'),
    loading: Loading
});
const FormViewer = Loadable({
    loader: () => import('./views/Form/FormViewer'),
    loading: Loading
});
const ListViewer = Loadable({
    loader: () => import('./views/List/NewListViewer'),
    loading: Loading
});
const Role = Loadable({
    loader: () => import('./views/Role/Role'),
    loading: Loading
})
const routes = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard
    },
    {
        path: '/pageEditor',
        name: 'Page Management',
        component: PageEditor
    },
    {
        path: '/form',
        name: 'FormViewer',
        component: FormViewer
    },
    {
        path: '/list',
        name: 'ListViewer',
        component: ListViewer
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile
    },
    {
        path: '/role',
        name: 'Role',
        component: Role
    }
];

export default routes;
