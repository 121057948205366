import React, { Component } from "react";
import { Layout, Dropdown, Menu, Avatar } from "antd";
import local from "../../services/local";
import helper from "../../services/helper";
import api from "../../services/api";
import { connect } from "react-redux";
import { Logout, Profile, Ring } from "../../assets/logo";
import { Link } from "react-router-dom";

const { Header } = Layout;
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from "@ant-design/icons";

const renderSwitchMenu = (collapsed, setCollapsed) => {
  return (
    <>
      {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: "trigger",
        onClick: () => setCollapsed(),
      })}
    </>
  );
};

class HeaderLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: local.get("user"),
    };
  }
  onLogoutClick() {
    window.location.href = "";
    api.logout();
    local.clear();
  }

  async addTabProfile() {
    this.props.dispatch({
      type: "ADD_TAB",
      payload: {
        key: "2",
        label: "Profile",
        path: "./profile",
        children: await helper.getComponentByRoute(
          this.props.history,
          "/profile"
        ),
      },
    });
  }

  dropdown = (
    <Menu
      items={[
        {
          label: (
            <Link onClick={this.addTabProfile.bind(this)} to={"./profile"}>
              <Profile />
              &nbsp; Profile
            </Link>
          ),
          key: "0",
        },
        {
          type: "divider",
        },
        {
          label: (
            <div onClick={this.onLogoutClick.bind(this)}>
              <Logout />
              &nbsp; Logout
            </div>
          ),
          key: "1",
        },
      ]}
    />
  );

  render() {
    return (
      <Header className="site-layout-background header-custom">
        <div className="logo">
          <img preview={false} src={this.props.company.logo} />
          <div className="name">{this.props.company.name}</div>
        </div>

        <div className="switch-menu">
          {renderSwitchMenu(this.props.collapsed, this.props.setCollapsed)}
        </div>
        <div className="user-btn ml-auto">
          <Dropdown
            overlay={this.dropdown}
            trigger={["click"]}
            placement="bottomRight"
            className="dropdown-header"
          >
            <Avatar className="icon-avatar-default" icon={<UserOutlined />} />
          </Dropdown>
        </div>
      </Header>
    );
  }
}

const mapStateToProps = (state) => {
  return { userInfo: state.userInfo, tabs: state.tabs, company: state.company };
};
export default connect(mapStateToProps)(HeaderLayout);
