import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./App.css";
// Styles
// CoreUI Icons Set
import "@coreui/icons/css/coreui-icons.min.css";
// Import Flag Icons Set
import "flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
import "font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
import "simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
// import './scss/style.css'
// import './scss/custom.css'
import "react-table/react-table.css";
import "react-table-hoc-fixed-columns/lib/styles.css";
import "react-image-lightbox/style.css";
// import css for react checkboc-tree
import "react-checkbox-tree/lib/react-checkbox-tree.css";

import "react-datepicker/dist/react-datepicker.css";

import "./scss/index.scss";
import "./scss/index.less";
// Containers
import { DefaultLayout } from "./containers";
// Pages
import Login from "./views/Login";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <HashRouter>
          <Switch>
            <Route exact path="/login" name="Login Page" component={Login} />
            <Route path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </HashRouter>
        <Helmet>
          <title>{this.props.company.name}</title>
          <link rel="shortcut icon" href={this.props.company.shortcutIcon} />
        </Helmet>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return { company:state.company };
};
export default connect(mapStateToProps)(App);
