const initialState = {
  listTabs: [],
  activeTab: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "SET_ACTIVE":
      return {
        ...state,
        activeTab: action.payload,
      };
    case "ADD_TAB":
      let check = false;
      state.listTabs.map((tab) => {
        if (tab.key === action.payload.key) check = true;
      });
      return {
        ...state,
        listTabs: !check
          ? [...state.listTabs, action.payload]
          : [...state.listTabs],
        activeTab: action.payload.key,
      };
    case "REMOVE_TAB":
      return {
        ...state,
        listTabs: state.listTabs.filter(
          (tab) => tab.key !== action.payload.targetKey
        ),
        activeTab: action.payload.newActiveKey,
      };
    case "CHANGE_TAB":
      state.listTabs.map((tab) => {
        if (tab.key === action.payload.key) {
          tab.children = action.payload.component;
        }
      });
      return {
        ...state,
      };
    default:
      return state;
  }
}
