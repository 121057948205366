import React, { useEffect, useState, useCallback } from "react";
import i18next from "i18next";

import api from "../../services/api.js";
import local from "../../services/local.js";

import "./index.scss";
import {EnglandFlag,AsystLoginLogo,MyanmarFlag,EmailIcon} from "../../assets/images";
import { ReloadOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Image, Checkbox, Input, Tooltip } from "antd";

const LoginPage = (props) => {
  const [captcha, setCaptcha] = useState(null);
  const [captchaId, setCaptchaId] = useState(0);

  const loadCaptcha = useCallback(async () => {
    try {
      let captInfo = await api.getCaptcha();
      setCaptcha(captInfo?.data);
      setCaptchaId(captInfo?.id);
    } catch (error) {
      console.log(error);
    }
  }, [captcha]);

  useEffect(() => {
    loadCaptcha();
  }, []);

  const onFinished = async (form) => {
    let rs = await api.login({
      ...form,
      captchaId,
    });
    if (rs && rs.data) {
      local.set("session", rs.data.token);
      local.set("user", JSON.stringify(rs.data.userInfo));
      props.history.push("/dashboard");
    } else {
      loadCaptcha();
    }
  };

  const renderFlag = () => {
    return (
      <div className="languages">
        <Tooltip title="England">
          <Col span={5} className="flag">
            <Image preview={false} width={40} src={EnglandFlag} />
            <span>&nbsp;Eng</span>
          </Col>
        </Tooltip>
        <Tooltip title="Myanmar">
          <Col span={5} className="flag">
            <Image preview={false} width={40} src={MyanmarFlag} />
            <span>&nbsp;Bur</span>
          </Col>
        </Tooltip>
      </div>
    );
  };

  return (
    <div className="box">
      <Row className="login-page">
        <Col
          sm={24}
          xs={24}
          xl={13}
          md={12}
          xxl={10}
          className="login-content_left"
        >
          <div>
            <div className="login-text_welcome">{i18next.t("textWelcome")}</div>
            <div className="login-logo_money mb-1">
              <Image preview={false} alt="" src={AsystLoginLogo} />
            </div>
            <div className="login-content_text">
              <p>{i18next.t("textContentCenter")}</p>
            </div>
          </div>
          <div className="login-email">
            <EmailIcon />
            <span>&nbsp;hello@Asyst.com.mm</span>
          </div>
        </Col>
        <Col sm={24} xs={24} md={10} xl={7} xxl={6}>
          {/* {renderFlag()} */}
          <Col span={24} className="login-content_right">
            <Form onFinish={onFinished} className="login-page-form">
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: i18next.t("requiredUsername"),
                  },
                ]}
              >
                <div className="form__group field" >
                  <Input
                    placeholder={i18next.t("Username")}
                    className="form__field"
                  />
                </div>
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: i18next.t("requiredPass"),
                  },
                ]}
              >
                <div className="form__group field">
                  <Input.Password
                    autoComplete="new-password"
                    placeholder={i18next.t("password")}
                    className="form__field"
                  />
                </div>
              </Form.Item>
              <Form.Item
                name="captchaText"
                rules={[
                  {
                    required: true,
                    message: i18next.t("requiredCaptcha"),
                  },
                ]}
              >
                <div className="form__group field">
                  <Input
                    suffix={<ReloadOutlined onClick={() => loadCaptcha()} />}
                    className="form__field"
                    placeholder={i18next.t("Captcha")}
                  />
                </div>
              </Form.Item>
              <Form.Item className="captcha-box">
                <div
                  className="captcha"
                  dangerouslySetInnerHTML={{
                    __html: captcha || "",
                  }}
                ></div>
              </Form.Item>
              <Form.Item>
                <div className="remember-forgot">
                  <Checkbox className="remember">
                    {i18next.t("remember")}
                  </Checkbox>
                  {/* <a href="/" className="forgot" target="_blank">
                    {i18next.t("forgotpass")}
                  </a> */}
                </div>
              </Form.Item>
              <Form.Item className="text-center">
                <Button className="btn-login" type="primary" htmlType="submit">
                  {i18next.t("login")}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Col>
      </Row>
    </div>
  );
};

export default LoginPage;
