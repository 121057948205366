import request from './request';

var config = {}



var path = {
    //login
    login: '/api/user/login',
    logout: '/api/user/logout',
    getCaptcha: '/api/user/create-captcha',
    getMeta: '/api/admin/get-meta',
    refreshToken: '/user/refreshToken',

    getLanguage: '/language/getList',
    getConfig: '/config/getConfig',

    findPermissionPage: '/admin/permission/findPerPage',
    savePermission: '/admin/permission/save',
    checkPermissionButton: '/admin/permission/checkPerButton',
    getListPermission: '/admin/permission/getList',

    getListRole: '/admin/role/getList',
    createRole: '/admin/role/create',
    updateRole: '/admin/role/update',

    changePassword: '/admin/user/changePassword'

};


Object.keys(path).forEach(function (key) {

    config[key] = async function (data) {
        let result = await request.request(path[key], data)
        return result
    }
}, this);




export default config;
