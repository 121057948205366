import local from "../services/local";
import {AsystHeaderLogo} from '../assets/images'
const companyConfigLocal = local.get("companyConfig") || null;
const initialState = {
    name: companyConfigLocal?.name || "Biller Portal",
    shortcutIcon:companyConfigLocal?.shortcutIcon ||'/assets/img/logo.png',
    logo: companyConfigLocal?.logo || AsystHeaderLogo,
    themeColor:'theme-color-ocean'
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "SET_THEME_COLOR":
        return {
          ...state,
          themeColor: action.payload,
        };
        case "SET_CONFIG":
        return {
          ...state,
          name: action.payload.name,
          logo: action.payload.logo,
          shortcutIcon: action.payload.shortcutIcon,
        };
        default:
            return state;
    }
}