import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import routes from "../../routes";
import helper from "../../services/helper";
import local from "../../services/local";
import api from "../../services/api";
import _ from "lodash";
import i18next from "i18next";
import TagsView from "./TagsView";
import Header from "./Header";
import {
  Layout,
  Menu,
  Drawer,
  Affix,
  Dropdown,
  Space,
  Input,
  Image,
} from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SearchOutlined,
} from "@ant-design/icons";
const { Sider, Content } = Layout;

class DefaultLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      nav: [],
      collapsed: false,
      themeColor: null,
    };
  }

  async componentDidMount() {
    try {
      var session = await local.get("session");
      if (!session) {
        this.props.history.replace("/login");
      } else {
        let meta = await api.getMeta();
        // let config = await api.getConfig({ keys: ['IMAGE', 'PAGE_ID', 'FILE_SETTING', 'REGEX', 'AMAZONE_S3'] })
        meta.pages.map((page) => {
          for (var i in page.schema) {
            page.schema[i].pageId = page.sid;
          }
          return null;
        });
        local.set("meta", JSON.stringify(meta));
        let userInfo = local.get("user");
        let nav = this.calculateNavAnt(meta.menus, userInfo.roles);
        this.setState({ loading: false, nav });
        this.props.dispatch({
          type: "SET_DATA",
          payload: {
            languages: [],
            configs: {},
          },
        });
        this.setState({
          themeColor: local.get("themeColor") || this.props.company.themeColor,
        });
      }
    } catch (err) {
      this.props.history.replace("/login");
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.company.themeColor !== this.props.company.themeColor) {
      this.setState({
        themeColor: nextProps.company.themeColor,
      });
    }
  };

  calculateNavAnt(allMenus, role) {
    let menus = allMenus;
    let k = 0;

    let items = [];
    menus.map((m) => {
      if (!m.parent) {
        m.isOpen = false;
        if (!m.url) m.url = "url";
        items.push(
          this.getItem(
            m.name,
            k,
            m.icon ? <i className={m.icon} /> : null,
            m?.children,
            m?.parent,
            m?.sid,
            m?.url,
            m.name
          )
        );
        k++;
      }
      return null;
    });

    menus.map((m) => {
      if (!m.parent) return null;
      for (var i = 0; i < items.length; i++) {
        if (items[i].sid === m.parent) {
          if (!items[i].children) {
            items[i].children = [];
          }
          items[i].children.push(
            this.getItem(
              m.name,
              k,
              m.icon ? <i className={m.icon} /> : null,
              m?.children,
              m?.parent,
              m?.sid,
              m?.url,
              m.name
            )
          );
          k++;
        }
      }
      return null;
    });

    return items;
  }

  getItem(label, key, icon, children, parent, sid, path, title) {
    return {
      key,
      icon,
      children,
      label,
      parent,
      sid,
      path,
      title,
    };
  }

  checkDevice() {
    const rect = document.body.getBoundingClientRect();
    const needCollapse = rect.width >= 992;
    if (needCollapse) return needCollapse;
  }

  renderSwitchMenu() {
    return (
      <div className="switch-menu">
        {React.createElement(
          this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          {
            className: "trigger",
            onClick: () => this.setState({ collapsed: !this.state.collapsed }),
          }
        )}
      </div>
    );
  }

  renderMenu(isMobile) {
    return isMobile ? (
      <Affix offsetTop={70}>
        <Sider
          className="sider-custom"
          breakpoint="lg"
          collapsed={this.state.collapsed}
          onCollapse={(value) => this.setState({ collapsed: value })}
          width={230}
          style={{
            overflow: "auto",
            position: "sticky",
            left: 0,
            top:70,
          }}
        >
          {/* <Input className="menu-form-search" prefix={!this.state.collapsed && <SearchOutlined  style={{color: "#757575"}}/>} placeholder={!this.state.collapsed ? 'Search' : '...'} /> */}
          <Menu
            mode="inline"
            items={this.state.nav}
            onClick={this.onClick}
            className="menu-custom"
            theme={"dark"}

          />
        </Sider>
      </Affix>
    ) : (
      <Drawer
        className={`drawer-custom ${this.state.themeColor}`}
        width={240}
        placement="left"
        bodyStyle={{ padding: 0, height: "100%" }}
        closable={false}
        onClose={() => this.setState({ collapsed: !this.state.collapsed })}
        open={this.state.collapsed}
      >
        <Menu
          mode="inline"
          items={this.state.nav}
          onClick={this.onClick}
          className="menu-custom"
          theme={"dark"}
        />
      </Drawer>
    );
  }

  onClick = async (e) => {
    let path = await e.item.props.path.split("?")[0];
    let param = await e.item.props.path.split("?")[1];
    await this.props.history.push(`../${e.item.props.path}`);
    await this.props.dispatch({
      type: "ADD_TAB",
      payload: {
        key: e.item.props.sid,
        label: e.item.props.title,
        path: e.item.props.path,
        children: await helper.getComponentByRoute(
          this.props.history,
          path,
          param
        ),
      },
    });
  };

  render() {
    if (this.state.loading) return <p>{i18next.t("PROCESSING")}</p>;
    return (
      <div className={this.state.themeColor}>
        <Layout>
          <Header
            company={this.props.company}
            collapsed={this.state.collapsed}
            setCollapsed={() =>
              this.setState({ collapsed: !this.state.collapsed })
            }
            style={{ position: "fixed", zIndex: 1, width: "100%" }}
          />
          <Layout className="main">
            {this.renderMenu(this.checkDevice())}
            <Layout>
              <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
                <TagsView />
              </Content>
            </Layout>
          </Layout>
        </Layout>
      </div>
    );
  }
}

// export default DefaultLayout;
const mapStateToProps = (state) => {
  return { userInfo: state.userInfo, tabs: state.tabs, company: state.company };
};
export default connect(mapStateToProps)(DefaultLayout);
