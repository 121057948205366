import React from "react";

function Logout() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.325 13.275C12.1417 13.0583 12.05 12.8123 12.05 12.537C12.05 12.2623 12.1417 12.0333 12.325 11.85L14.175 10H7C6.71667 10 6.47933 9.904 6.288 9.712C6.096 9.52067 6 9.28333 6 9C6 8.71667 6.096 8.479 6.288 8.287C6.47933 8.09567 6.71667 8 7 8H14.175L12.325 6.15C12.125 5.95 12.025 5.71267 12.025 5.438C12.025 5.16267 12.125 4.925 12.325 4.725C12.5083 4.525 12.7377 4.425 13.013 4.425C13.2877 4.425 13.5167 4.51667 13.7 4.7L17.3 8.3C17.4 8.4 17.471 8.50833 17.513 8.625C17.5543 8.74167 17.575 8.86667 17.575 9C17.575 9.13333 17.5543 9.25833 17.513 9.375C17.471 9.49167 17.4 9.6 17.3 9.7L13.7 13.3C13.4833 13.5167 13.246 13.6123 12.988 13.587C12.7293 13.5623 12.5083 13.4583 12.325 13.275ZM2 18C1.45 18 0.979 17.8043 0.587 17.413C0.195667 17.021 0 16.55 0 16V2C0 1.45 0.195667 0.979 0.587 0.587C0.979 0.195667 1.45 0 2 0H8C8.28333 0 8.521 0.0956666 8.713 0.287C8.90433 0.479 9 0.716667 9 1C9 1.28333 8.90433 1.52067 8.713 1.712C8.521 1.904 8.28333 2 8 2H2V16H8C8.28333 16 8.521 16.096 8.713 16.288C8.90433 16.4793 9 16.7167 9 17C9 17.2833 8.90433 17.5207 8.713 17.712C8.521 17.904 8.28333 18 8 18H2Z"
        fill="#1C1B1F"
      />
    </svg>
  );
}

export default Logout;
